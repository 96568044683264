.app {
  width: 100%;
  height: 100vh;
}

.view_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wht_bg_a {
  background: white;
}

.ntv_bg_a {
  background: rgba(212, 13, 22, 1);
}

.ntv_bg_b {
  background: rgba(212, 13, 22, 0.5);
}

.gry_bg_a {
  background: rgba(136, 136, 136, 0.7);
}

.mrd_bg_a {
  background: #c1aba6;
}

.blu_bg_a {
  background: #6667ab;
}

.blu_bg_b {
  background: #a4e9d5;
}

.pnk_bg_a {
  background: #ed254e;
}

.brd_rg_ntv_a {
  border-right: 3px solid rgba(212, 13, 22, 0.5);
}

.crs_pointer {
  cursor: pointer;
}

.crs_default {
  cursor: default;
}

.color_wht_a {
  color: white;
}

.color_gry_a {
  color: #888888;
}

.color_gry_b {
  color: rgba(136, 136, 136, 0.5);
}

.txt_20 {
  font-size: 20px;
}

.txt_16 {
  font-size: 16px;
}

.txt_14 {
  font-size: 14px;
}

.txt_12 {
  font-size: 12px;
}

.single_line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.btn_hvr_gry {
  cursor: pointer;
}

.btn_hvr_gry:hover {
  background: rgba(136, 136, 136, 0.1);
}

td,
th {
  min-width: 140px;
  text-align: left;
  padding: 8px 10px;
}

tr:nth-child(even) {
  background-color: rgba(217, 217, 217, 0.5);
}

tbody tr:hover {
  background-color: rgb(217, 217, 217);
}

.ctn_dropdown {
  height: 40px;
  min-width: 150px;
  display: flex;
}

.chd_dropdown {
  width: 100%;
  flex: 1;
}

.foc_dropdown {
  border-left: 1px solid rgb(217, 217, 217);
  border-right: 1px solid rgb(217, 217, 217);
  border-top: 1px solid rgb(217, 217, 217);
}

.ctn_choice_dropdown {
  margin-bottom: 20px;
  border-left: 1px solid rgb(217, 217, 217);
  border-right: 1px solid rgb(217, 217, 217);
  border-bottom: 1px solid rgb(217, 217, 217);
}

.disable_view {
  visibility: hidden;
}

.ctn_button {
  height: 40px;
  min-width: 100px;
  display: flex;
}

.chd_button {
  flex: 1;
  padding: 0 10px;
  border-radius: 8px;
}

.ctn_input {
  display: flex;
  min-width: 150px;
  height: 40px;
}

input {
  padding: 0 10px;
  background: transparent;
  outline: none;
  border-width: 0;
  flex: 1;
}

.in_deactive {
  pointer-events: none;
  font-style: italic;
}

::placeholder {
  color: rgba(136, 136, 136, 0.5);
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba(136, 136, 136, 0.5);
}

::-ms-input-placeholder {
  color: rgba(136, 136, 136, 0.5);
}

.admin_nav_ctn {
  height: 60px;
  width: 100%;
}

.adm_tbr_ctn {
  height: 50px;
  width: 100%;
}

.adm_tbr_act {
  min-width: 60px;
}

.adm_tbl_ctn {
  height: 100%;
  display: flex;
  flex-direction: row;
  width: auto;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.step-wizard {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-wizard-list {
  background: #fff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
  color: #333;
  list-style-type: none;
  border-radius: 10px;
  display: flex;
  padding: 20px 10px;
  position: relative;
  z-index: 10;
}

.step-wizard-item {
  padding: 0 0px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 100px;
  position: relative;
}

.step-wizard-item+.step-wizard-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  background: rgba(212, 13, 22, 1);
  ;
  width: 100%;
  height: 2px;
  transform: translateX(-50%);
  z-index: -10;
}

.progress-count {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}

.progress-count:after {
  content: "";
  height: 40px;
  width: 40px;
  background: rgba(212, 13, 22, 1);
  ;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -10;
}

.progress-count:before {
  content: "";
  height: 10px;
  width: 20px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) rotate(-45deg);
  transform-origin: center center;
}

.progress-label {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.current-item .progress-count:before,
.current-item~.step-wizard-item .progress-count:before {
  display: none;
}

.current-item~.step-wizard-item .progress-count:after {
  height: 10px;
  width: 10px;
}

.current-item~.step-wizard-item .progress-label {
  opacity: 0.5;
}

.current-item .progress-count:after {
  background: #fff;
  border: 2px solid rgba(212, 13, 22, 1);
  ;
}

.current-item .progress-count {
  color: rgba(212, 13, 22, 1);
  /* background: rgba(212, 13, 22, 1); */

}

/* input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  color: #fff;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  padding: 2px;
  color: #000;
  left: 56px;
}

input[type="date"]::-webkit-datetime-edit-month-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  padding: 2px;
  color: #000;
  left: 26px;
}


input[type="date"]::-webkit-datetime-edit-day-field {
  position: absolute !important;
  color: #000;
  padding: 2px;
  left: 4px;

} */